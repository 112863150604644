/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// using admin-lte app
require('admin-lte');

// using POST on a <a> tag
require('jquery-ujs');

/** For datatables **/
require('datatables.net-bs4');
require('datatables.net-responsive-bs4');

/** For forms **/
require('select2/dist/js/select2.full');

/** For Bootstrap Toggle **/
require('bootstrap4-toggle')
